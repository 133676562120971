<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav mr-1">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username || userData.email }}
        </p>
        <span class="user-status">{{ userData.role || userData.profile.type }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.image"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.image" icon="UserIcon" size="22" />
      </b-avatar>
    </template>
    <div v-if="userData.video_wall === 1">
      <b-dropdown-item link-class="d-flex align-items-center" @click="logoutVideoWall()">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>{{ $t('logout') }}</span>
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item
        :to="{ name: 'user_profile' }"
        link-class="d-flex align-items-center"
        v-if="$can('read_profile', 'User')"
      >
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>{{ $t('profile') }}</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>{{ $t('logout') }}</span>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import usePassport from "@/auth/passport/usePassport";
import { avatarText } from "@core/utils/filter";
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  mounted() {
    setTimeout(this.connectPresenceChannel, 3000);
  //  this.connectPresenceChannel();
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem(usePassport.passportConfig.storageTokenKeyName);
      localStorage.removeItem(usePassport.passportConfig.storageRefreshTokenKeyName);
      window.Echo.disconnect();
      window.abortController.abort();
      window.abortController = new AbortController();

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // clear all data in vuex
      for (const [key, value] of Object.entries(this.$store._actions)) {
        if (key.endsWith("/clear")) this.$store.dispatch(key);
      }

      // Reset ability
      this.$ability.update(initialAbility);

      document.location.reload()

      // Redirect to login page
      // this.$router.push({ name: "auth-login" });
    },
    logoutVideoWall() {
      store
        .dispatch("video_wall/updateVideoWallUserStatus", {
          id_user: this.userData.id,
          status: "offline",
        })
        .then(() => {
          // Remove userData from localStorage
          localStorage.removeItem(usePassport.passportConfig.storageTokenKeyName);
          localStorage.removeItem(usePassport.passportConfig.storageRefreshTokenKeyName);
          window.Echo.disconnect();
          window.abortController.abort();
          window.abortController = new AbortController();

          // Remove userData from localStorage
          localStorage.removeItem("userData");

          // clear all data in vuex
          for (const [key, value] of Object.entries(this.$store._actions)) {
            if (key.endsWith("/clear")) this.$store.dispatch(key);
          }

          // Reset ability
          this.$ability.update(initialAbility);

          // Redirect to login page
          this.$router.push({ name: "auth-login" });
        })
        .catch((response) => console.log(response));
    },
    connectPresenceChannel() {
      if (!window.Echo.connector.socket.connected)
      {
        window.Echo.connect();
        setTimeout(this.connectPresenceChannel, 30000);
      } else {
        window.Echo.join("users");
      }
    }
  },
};
</script>
