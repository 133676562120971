import { onMounted, reactive, nextTick, toRefs, toRef, ref } from "@vue/composition-api";
import store from "@/store";
import LayoutApi from "@/libs/LayoutApi";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import { EventBus } from "@/libs/event-bus";

export default function (dataPages) {

  const type = "action_plan";
  const titleType = "Action Plans";
  const layoutApi = new LayoutApi(store);

  const getActionPlans = () => {
    store
      .dispatch("action_plan/getAll")
      .then((resp) => {
        const actionPlansData = [];

        resp.data.forEach((actionPlan, index) => {
          actionPlansData[index] = {
            title: actionPlan.name,
            description: actionPlan.name,
            id_action_plan: actionPlan.id,
            icon: "GitPullRequestIcon",
            type,
            titleType,
            isBookmarked: true,
          };
        });

        dataPages.value['actionPlans'] = {
          key: "title",
          data: actionPlansData,
        };

      })
      .catch((response) => {
        console.error(response);
      });

  };

  const searchActiveActionPlan = () => {

    const currentTabData = layoutApi.getCurrentTabData();
    let foundCount = 0;
    let ActiveActionPlanId = null;

    currentTabData.slots.forEach((slot) => {
      if (slot.type === SLOT_TYPES.ACTION_PLAN) {
        ++foundCount;
        ActiveActionPlanId = slot.data.id_action_plan;
      }
    });

    return { ActiveActionPlanId, foundCount };
  }

  const onActionPlanSelected = (actionPlan) => {

    const { ActiveActionPlanId, foundCount } = searchActiveActionPlan();

    if (foundCount === 1) {

      EventBus.emit(`add_action_plan_${ActiveActionPlanId}`, { actionPlanId: actionPlan.id_action_plan });

    } else if (foundCount > 1) {

      alert('Existen más de un plan de acción activo');

    } else {

      layoutApi.setSlotData(
        SLOT_TYPES.ACTION_PLAN,
        `${actionPlan.name}`,
        { id_action_plan: actionPlan.id_action_plan },
        SOURCES.MAIN_SEARCH
      );

    }

  };

  return {
    getActionPlans,
    onActionPlanSelected,
  };
}
