<template>
  <b-nav-item-dropdown class="dropdown-notification mr-75" menu-class="dropdown-menu-media dropdown-menu-right" left>
    <template #button-content>
      <feather-icon :badge="notifications.length +
        ($can('manage_request_record_video', 'Video') ? recording_request.length : 0)
        " badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <li class="dropdown-menu-header" v-if="recording_request.length > 0 && $can('manage_request_record_video', 'Video')"
      :to="`/recording_request`" Read all notifications>
      <b-link :to="`/recording_request`">
        <div class="dropdown-header d-flex">
          <!-- TODO: Translate this -->
          <h4 class="notification-title mb-0 mr-auto">Recording Request</h4>
          <b-badge pill variant="light-primary">
            {{ `${recording_request.length} new recording request` }}</b-badge>
        </div>
      </b-link>
    </li>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">{{ $t("alarm.alarms") }}</h4>
        <b-badge pill variant="light-primary">
          {{ `${notifications.length} ${$t("alarm.new_alarms")}` }}</b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
      tagname="li">
      <!-- Account Notification -->
      <b-link v-for="notification in notifications" :key="notification.id" @click="openAlarmData(notification)">
        <b-media>
          <template #aside>
            <b-avatar size="32" :text="notification.alarm_acronym"
              :style="{ backgroundColor: notification.alarm_level.color }">
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ `${notification.unit.description}` }}
              <small class="notification-text float-right">{{
                notification.created_format_1
              }}</small>
            </span>
          </p>
          <small class="notification-text">{{ notification.alarm_status }}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
                <h6 class="font-weight-bolder mr-auto mb-0">{{ $t('downloads') }}</h6>
                <b-form-checkbox :checked="true" switch />
            </div> -->

      <!-- System Notifications -->
      <!-- <b-link v-for="notification in systemNotifications" :key="notification.subtitle">
                <b-media>
                    <template #aside>
                        <b-avatar size="32" :variant="notification.type">
                            <feather-icon :icon="notification.icon" />
                        </b-avatar>
                    </template>
                    <p class="media-heading">
                        <span class="font-weight-bolder">
                            {{ notification.title }}
                        </span>
                    </p>
                    <small class="notification-text">{{ notification.subtitle }}</small>
                </b-media>
            </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="removeAllNotification()">{{
        $t("notifications.read_allnotifications") }}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { computed, onMounted, onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    store.dispatch("recording_request/getAllNotViewed");
    const notifications = computed(() => store.getters["grid/getAlarms"]);
    const recording_request = computed(
      () => store.getters["recording_request/getItemsNotViewed"]
    );

    const openAlarmData = (data) => {
      // store.dispatch("grid/setIncomingAlarm", data);
      store.dispatch("grid/setAlarmId", data.id);
      store.commit(
        "psimConfig/UPDATE_TOOLBARS_COLLAPSED_RIGHT",
        0
      );
      store.commit(
        "psimConfig/UPDATE_TOOLBARS_COLLAPSED",
        1
      );

      store.dispatch("grid/removeNotificationAlarm", data);
    };

    const removeAllNotification = () => {
      store.dispatch("grid/removeAllNotificationAlarm");
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const connectSocket = () => {
      if(!window.Echo.connector.socket.connected) window.Echo.connect();

      window.Echo.private(`recording-request`)
        .listen(".updated", (data) => {
          store.dispatch("recording_request/getAllNotViewed");
        })
        .listen(".created", (data) => {
          store.dispatch("recording_request/getAllNotViewed");
        });
    }

    onMounted(() => {
      setTimeout(connectSocket, 2000);
    });

    onUnmounted(() => {
      window.Echo.leave(`recording-request`);
    });

    return {
      notifications,
      recording_request,
      perfectScrollbarSettings,
      openAlarmData,
      removeAllNotification,
    };
  },
};
</script>

<style></style>
