export default [
  {
    header: 'Demo',
  },
  {
    title: 'Opción 1',
    icon: 'MailIcon',
  },
  {
    title: 'Opción 2',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Opción 3',
    icon: 'CheckSquareIcon',
  },
]
