import { onMounted, reactive, nextTick, toRefs, toRef, ref } from "@vue/composition-api";
import store from "@/store";
import LayoutApi from "@/libs/LayoutApi";
import { SLOT_TYPES,SOURCES } from "@/config/layoutConfig";

export default function (dataPages) {
  const type = "tag";
  const titleType = "Tags";
  const layoutApi = new LayoutApi(store);

  const getTags = () => {
    store
      .dispatch("video_tag/getAll")
      .then((resp) => {
        const tagsData = [];

        resp.data.forEach((tag, index) => {
          tagsData[index] = {
            title: tag.request_tag.tag,
            description: tag.description,
            icon: "TagIcon",
            isBookmarked: true,
            type,
            titleType,
            camera: tag.camera_proxy,
            created: tag.tag_created,
          };
        });

        dataPages.value['tags'] = {
          key: "title",
          data: tagsData,
        };
      })
      .catch((response) => {
        console.error(response);
      });
  };

  const onTagSelected = (tag) => {
    const camera = tag.camera;
    const created = tag.created;
    const cameraName = tag.camera.name;
    const camType = SLOT_TYPES.CAM_PA;
    const source  = SOURCES.MAIN_SEARCH

    const data = { camera, ts: created };
    layoutApi.setSlotData(camType, cameraName, data, source);
  };

  return {
    getTags,
    onTagSelected
  };
}
