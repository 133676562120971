<template>
  <b-navbar-nav class="nav">
    <!--<b-nav-item
      v-for="(bookmark, index) in bookmarks"
      :id="`bookmark-${index}`"
      :key="index"
      :to="bookmark.route"
    >
      <feather-icon
        :icon="bookmark.icon"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        :target="`bookmark-${index}`"
        :title="bookmark.title"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-nav-item>-->
    <b-nav-item-dropdown link-classes="bookmark-star" lazy @hidden="resetsearchQuery">
      <feather-icon
        slot="button-content"
        icon="SearchIcon"
        size="21"
        class="text-warning"
      />

      <!-- Dropdown Content -->
      <li style="min-width: 300px">
        <div class="p-1">
          <b-form-input
            id="boomark-search-input"
            v-model="searchQuery"
            :placeholder="`${$t('search')}...`"
            autofocus
          />
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="search-list search-list-bookmark scroll-area"
          :class="{ show: filteredData.pages && filteredData.pages.length }"
          tagname="ul"
        >
          <span
            v-for="(suggestion, index) in filteredData"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center pl-2"
            :to="suggestion.route"
            @mouseenter="currentSelected = index"
          >
            <b-dropdown-group
              :id="index"
              :header="suggestion[0] && suggestion[0].titleType"
            >
              <b-dropdown-item
                v-for="(element, index) in suggestion"
                :key="index"
                class="suggestion-group-suggestion cursor-pointer"
                link-class="d-flex align-items-center"
                @mouseenter="currentSelected = index"
                @click="onItemClick(suggestion[0].type, element)"
              >
                <feather-icon :icon="element.icon" class="mr-75" size="18" />
                <p class="w-100" v-if="suggestion.type === 'tag'">
                  <span style="opacity: 0.8; font-size: small; float: right">{{
                    element.created
                  }}</span>
                  <br />
                  <span>{{ `${element.title} - ${element.camera.name}` }}</span>
                  <br />
                  {{ element.description }}
                </p>
                <p class="w-100" v-else>
                  {{ element.description }}
                </p>
              </b-dropdown-item>
            </b-dropdown-group>
          </span>

          <b-dropdown-item
            v-show="filteredDataLength === 0"
            disabled
          >
            {{ $t("no_results_found") }}.
          </b-dropdown-item></vue-perfect-scrollbar
        >
      </li>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav,
  BNavItem,
  BTooltip,
  BNavItemDropdown,
  BFormInput,
  BDropdownItem,
  BDropdownDivider,
  BDropdownGroup,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAutoSuggest from "@core/components/app-auto-suggest/useAutoSuggest";
import { computed, ref, watch } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import searchAndBookmarkData from "../search-and-bookmark-data";
import useTags from "@/composables/mainSearch/useTags";
import useActionPlan from "@/composables/mainSearch/useActionPlan";

export default {
  components: {
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
    BDropdownDivider,
    BDropdownGroup,
  },
  setup() {
    const searchAndBookmarkDataPages = ref({});

    const { getTags, onTagSelected } = useTags(searchAndBookmarkDataPages);
    const { getActionPlans, onActionPlanSelected } = useActionPlan(
      searchAndBookmarkDataPages
    );

    const bookmarks = ref(
      searchAndBookmarkData.pages.data.filter((page) => page.isBookmarked)
    );
    const currentSelected = ref(-1);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const { searchQuery, resetsearchQuery, filteredData } = useAutoSuggest({
      data: searchAndBookmarkDataPages,
      searchLimit: 6,
    });

    watch(searchQuery, (val) => {
      store.commit("app/TOGGLE_OVERLAY", Boolean(val));
    });

    watch(filteredData, (val) => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0;
    });

    const filteredDataLength = computed(() => {
      return Object.keys(filteredData.value).length;
    });

    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value];
      router.push(suggestion.route).catch(() => {});
      resetsearchQuery();
    };

    const onItemClick = (type, item) => {
      switch (type) {
        case "tag":
          onTagSelected(item);
          break;

        case "action_plan":
          onActionPlanSelected(item);
          break;
      }
    };

    getTags();
    getActionPlans();

    setTimeout(() => {
      window.Echo.private(`video-tags`).listen(".created", (data) => {
        getTags();
      });
    }, 300);

    return {
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
      filteredDataLength,
      onItemClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
