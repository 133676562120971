<template>
  <b-nav-item @click="toggleDarkMode">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
      v-b-tooltip.hover.bottom.viewport
      :title="`${isDark ? $t('light_mode') : $t('dark_mode')}`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import { EventBus } from "@/libs/event-bus";
import { VBTooltip } from "bootstrap-vue";


export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    const toggleDarkMode = () => {
      if (isDark.value) {
        skin.value = 'light';
        EventBus.emit("toogle-dark-mode", "light");
      } else {
        skin.value = 'dark'
        EventBus.emit("toogle-dark-mode", "dark");
      }
    };

    return {
      skin,
      isDark,
      toggleDarkMode
    }
  },
}
</script>
