<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="
        () => {
          $i18n.locale = localeObj.locale;
          setLang(localeObj.locale);
        }
      "
    >
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { getCurrentInstance } from "@vue/composition-api";
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { localize } from 'vee-validate'
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      const preferencesLocale =
        "lang" in store.getters["user/getPreferences"]
          ? store.getters["user/getPreferences"].lang
          : "en";
      return this.locales.find((l) => l.locale === preferencesLocale);
    },
  },

  setup() {
    const vm = getCurrentInstance().proxy;

    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Español",
      },
      {
        locale: "pt",
        img: require("@/assets/images/flags/pt.jpg"),
        name: "Português",
      },
    ];

    const setLang = (lang) => {
      store.dispatch("i18n/setMessages", vm.$i18n);
      store.dispatch("user/updateUserConfig", { preferences: { lang } });
      localize(lang);
    };

    /* eslint-disable global-require */

    return {
      locales,
      setLang,
    };
  },
};
</script>

<style></style>
