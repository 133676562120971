export default [
  {
    header: "Menu",
    icon: "AlarmIcon",
    children: [
      {
        title: "VaaS",
        icon: "-",
        children: [
          {
            title: "Camera Manager",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "cm_master_account.title",
                icon: "-",
                route: "master_account",
                resource: "CameraManager",
                action: "read_masters_accounts",
              },
              {
                title: "reseller.title",
                icon: "-",
                route: "reseller",
                resource: "CameraManager",
                action: "read_resellers",
              },
              {
                title: "Cameras",
                icon: "-",
                route: "camera",
                resource: "CameraManager",
                action: "read",
              },
              {
                title: "status_cameras.name",
                icon: "-",
                route: "status_cameras",
                resource: "CameraManager",
                action: "read_status",
              },
            ],
          },
          {
            title: "safr.title",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "Cameras",
                icon: "-",
                route: "safr_cameras",
                resource: "CameraManager",
                action: "read_masters_accounts",
              },
              {
                title: "Master Accounts",
                icon: "-",
                route: "safr_master_accounts",
                resource: "CameraManager",
                action: "read_masters_accounts",
              },
              {
                title: "Access Clearences",
                icon: "-",
                route: "safr_access_clearances",
                resource: "CameraManager",
                action: "read_masters_accounts",
              },
            ],
          },
          {
            title: "EE VMS",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "Master Accounts",
                icon: "-",
                route: "bridge_master_account",
                resource: "Bridge",
                action: "read_masters_accounts",
              },
              {
                title: "Accounts",
                icon: "-",
                route: "account",
                resource: "Bridge",
                action: "read_accounts",
              },
              {
                title: "Bridges",
                icon: "-",
                route: "bridge",
                resource: "Bridge",
                action: "read_bridge",
              },
              {
                title: "Cameras",
                icon: "-",
                route: "bridge_camera",
                resource: "Bridge",
                action: "read",
              },
              {
                title: "presets_ptz.title",
                icon: "-",
                route: "presets",
                resource: "CameraBridge",
                action: "read_presets",
              },
            ],
          },
          {
            title: "luxriot.title",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "Master Accounts",
                icon: "-",
                route: "luxriot_master_accounts",
                resource: "LuxriotMasterAccount",
                action: "read",
              },
              {
                title: "Cameras",
                icon: "-",
                route: "luxriot_cameras",
                resource: "LuxriotCameras",
                action: "read",
              },
            ],
          },
          {
            title: "omnicloud",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "Master Accounts",
                icon: "-",
                route: "omnicloud_master_account",
                resource: "MasterAccount",
                action: "read",
              },
              {
                title: "Cameras",
                icon: "-",
                route: "omnicloud_camera",
                resource: "CameraOmnicloud",
                action: "read",
              },
              {
                title: "omniview_reseller.title",
                icon: "-",
                route: "omniview_reseller",
                resource: "OmniviewReseller",
                action: "read",
              },
            ],
          },
          {
            title: "dahua_camera.dahua",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "dahua_dvr.title",
                icon: "-",
                route: "dahua_dvr",
                resource: "DahuaDvr",
                action: "read",
              },
              {
                title: "dahua_camera.title",
                icon: "-",
                route: "dahua_camera",
                resource: "DahuaCamera",
                action: "read",
              },
            ],
          },
          {
            title: "generic_devices.title",
            icon: "-",
            route: "generic_devices",
            resource: "GenericDevice",
            action: "read",
          },
          {
            title: "recording_request.title",
            icon: "-",
            resource: "Home",
            action: "read",
            children: [
              {
                title: "Tag",
                icon: "-",
                route: "bbva_request_tag",
                resource: "Video",
                action: "manage_request_record_video",
              },
              {
                title: "recording_request.title",
                icon: "-",
                route: "recording_request",
                resource: "Video",
                action: "request_record_video",
              },
            ],
          },
          {
            title: "analytics",
            icon: "-",
            children: [
              {
                title: "facial_recognition.facial_recognition",
                icon: "-",
                resource: "Home",
                action: "read",
                children: [
                  {
                    title: "safr.title",
                    icon: "-",
                    resource: "Home",
                    action: "read",
                    children: [
                      {
                        title: "safr.events",
                        icon: "-",
                        route: "safr_events",
                        resource: "FaceRecognition",
                        action: "read",
                      },
                      {
                        title: "safr.groups",
                        icon: "-",
                        route: "safr_groups",
                        resource: "FaceRecognition",
                        action: "read",
                      },
                      {
                        title: "safr.people",
                        icon: "-",
                        route: "safr_people",
                        resource: "FaceRecognition",
                        action: "read",
                      },
                    ],
                  },
                  {
                    title: "luna.title",
                    icon: "-",
                    resource: "Home",
                    action: "read",
                    children: [
                      {
                        title: "luna.master_account.title",
                        icon: "-",
                        route: "luna_master_account",
                        resource: "LunaMasterAccount",
                        action: "read",
                      },
                      {
                        title: "luna.list.title",
                        icon: "-",
                        route: "luna_list",
                        resource: "LunaList",
                        action: "read",
                      },
                      {
                        title: "luna.face.title",
                        icon: "-",
                        route: "luna_face",
                        resource: "LunaFace",
                        action: "read",
                      },
                      {
                        title: "facial_recognition.title",
                        icon: "-",
                        route: "facial_recognition",
                        resource: "FaceRecognition",
                        action: "read_match",
                      },
                      {
                        title: "luna.facestream.title",
                        icon: "-",
                        route: "luna_facestream",
                        resource: "LunaFacestream",
                        action: "read",
                      },
                      {
                        title: "luna.face_detection.title",
                        icon: "-",
                        route: "face_detection",
                        resource: "LunaList",
                        action: "read",
                      },
                    ],
                  },
                ],
              },
              {
                title: "lpr.title",
                icon: "-",
                resource: "Home",
                action: "read",
                children: [
                  {
                    title: "plate_list._name",
                    icon: "-",
                    route: "plate_list",
                    resource: "PlateList",
                    action: "read",
                  },
                  {
                    title: "plate.title",
                    icon: "-",
                    route: "plate",
                    resource: "Plate",
                    action: "read",
                  },
                  {
                    title: "lpr_matches._name",
                    icon: "-",
                    route: "lpr_matches",
                    resource: "Lpr",
                    action: "read_match",
                  },
                ],
              },
              {
                title: "detection_event.title",
                icon: "-",
                route: "detection_event",
                resource: "DetectionEvent",
                action: "read",
              },
              {
                title: "intelexvision.title",
                icon: "-",
                children: [
                  {
                    title: "intelexvision.servers",
                    icon: "-",
                    route: "server",
                    resource: "Intelexvision",
                    action: "read",
                  },
                  {
                    title: "intelexvision_alarm_type.alarm_type",
                    icon: "-",
                    route: "intelexvision_alarm_type",
                    resource: "Intelexvision",
                    action: "read",
                  },
                  {
                    title: "intelexvision_event.title",
                    icon: "-",
                    route: "analytic_record",
                    resource: "Intelexvision",
                    action: "read",
                  },
                ],
              },
            ],
          },
          {
            title: "video_watermarks.title",
            icon: "-",
            route: "video_watermarks",
            resource: "VideoWaterMark",
            action: "read",
          },
        ],
      },
      {
        title: "tenants.title",
        icon: "-",
        children: [
          {
            title: "tenants.all",
            icon: "-",
            route: "tenant",
            resource: "Tenant",
            action: "read",
          },
          {
            title: "master_tenants.title",
            icon: "-",
            route: "master_tenant",
            resource: "MasterTenant",
            action: "read",
          },
          {
            title: "incident_list.title",
            icon: "-",
            route: "incident_list",
            resource: "Incident",
            action: "read",
          },
          {
            title: "chart_incident.title",
            icon: "-",
            route: "incident_chart",
            resource: "Incident",
            action: "read_chart",
          },
          {
            title: "log_incident.title",
            icon: "-",
            route: "log_incident",
            resource: "LogIncident",
            action: "read",
          },
          {
            title: "layout_builder.title",
            icon: "-",
            route: "layouts",
            resource: "Layouts",
            action: "read",
          },
          {
            title: "device_tag.title",
            icon: "-",
            route: "device_tags",
            resource: "Tenant",
            action: "read",
          },
          {
            title: "token.title",
            icon: "-",
            route: "token",
            resource: "Token",
            action: "read",
          },
          {
            title: "token_omnitag.title",
            icon: "-",
            route: "tokens_omnitag",
            resource: "TokenOmnitag",
            action: "read",
          },
        ],
      },
      {
        title: "users.title",
        icon: "-",
        children: [
          {
            title: "All Users",
            icon: "-",
            route: "user",
            resource: "User",
            action: "read",
          },
          {
            title: "profiles.title",
            icon: "-",
            route: "profile",
            resource: "Profile",
            action: "read",
          },
          {
            title: "permissions.title",
            icon: "-",
            route: "permission",
            resource: "Permission",
            action: "read",
          },
          {
            title: "group.title",
            icon: "-",
            route: "group",
            resource: "Group",
            action: "read",
          },
          {
            title: "users_app.title",
            icon: "-",
            route: "users_app",
            resource: "UsersApp",
            action: "read_users_app",
          },
        ],
      },
      {
        title: "alarm.title",
        icon: "-",
        children: [
          {
            title: "alarm.all",
            icon: "-",
            route: "alarm",
            resource: "Alarm",
            action: "read_history",
          },
          {
            title: "seproban.title",
            icon: "-",
            route: "seproban",
            resource: "SeprobanRequest",
            action: "read",
          },
          {
            title: "types_alarms.title",
            icon: "-",
            route: "type_alarm",
            resource: "TypeAlarm",
            action: "read",
          },
          {
            title: "alarm_level.title",
            icon: "-",
            route: "alarm_level",
            resource: "AlarmLevel",
            action: "read",
          },
          {
            title: "alarm_comment.title",
            icon: "-",
            route: "alarm_comment",
            resource: "AlarmComment",
            action: "read",
          },
          {
            title: "alarm_views.title",
            icon: "-",
            route: "alarm_views",
            resource: "AlarmViews",
            action: "read",
          },
          {
            title: "purge_alarm.title",
            icon: "-",
            route: "alarm_purge",
            resource: "PurgeAlarm",
            action: "read",
          },
          {
            title: "schedule_alarm.title",
            icon: "-",
            route: "schedule_alarm",
            resource: "ScheduleAlarm",
            action: "read",
          },
        ],
      },
      {
        title: "unit.title",
        icon: "-",
        children: [
          {
            title: "unit.all",
            icon: "-",
            resource: "Unit",
            route: "unit",
            action: "read",
          },
          {
            title: "centers.title",
            icon: "-",
            route: "center",
            resource: "Center",
            action: "read",
          },
          {
            title: "sectors.title",
            icon: "-",
            route: "sector",
            resource: "Sector",
            action: "read",
          },
          {
            title: "country.title",
            icon: "-",
            route: "countries",
            resource: "Countries",
            action: "read",
          },
          {
            title: "region.title",
            icon: "-",
            route: "region",
            resource: "Region",
            action: "read",
          },
          {
            title: "municipality.title",
            icon: "-",
            route: "municipality",
            resource: "Municipality",
            action: "read",
          },
          {
            title: "unit_groups.title",
            icon: "-",
            resource: "UnitGroups",
            action: "read",
            route: "unit_groups",
          },
        ],
      },
      {
        title: "iot",
        icon: "-",
        children: [
          {
            title: "buttons.title",
            icon: "-",
            children: [
              {
                title: "buttons.all",
                icon: "-",
                route: "buttons",
                resource: "Button",
                action: "read",
              },
              {
                title: "buttons.button_type",
                icon: "-",
                route: "button_type",
                resource: "Button",
                action: "read_type",
              },
            ],
          },
          {
            title: "swift.title",
            icon: "-",
            children: [
              {
                title: "swift_sensor.title",
                icon: "-",
                route: "swift_sensor",
                resource: "Swift",
                action: "read_sensor",
              },
              {
                title: "swift_account.title",
                icon: "-",
                route: "swift_account",
                resource: "Swift",
                action: "read_account",
              },
              {
                title: "swift_collector.title",
                icon: "-",
                route: "swift_collector",
                resource: "Swift",
                action: "read_account",
              },
            ],
          },
          {
            title: "access_devices.title",
            icon: "-",
            route: "access_devices",
            resource: "AccessDevices",
            action: "read",
          },
          {
            title: "density_device.density_devices",
            icon: "-",
            children: [
              {
                title: "density_device.title",
                icon: "-",
                route: "density_master_account",
                resource: "DensityMasterAccount",
                action: "read",
              },
              {
                title: "density_device.density_devices",
                icon: "-",
                route: "density_device",
                resource: "DensityDevice",
                action: "read",
              },
            ],
          },
        ],
      },
      {
        title: "logs.title",
        icon: "-",
        children: [
          {
            title: "logs.system_logs",
            icon: "-",
            route: "logs",
            resource: "Log",
            action: "read",
          },
          {
            title: "traffic.title",
            icon: "-",
            resource: "Log",
            action: "read",
            route: "traffic",
          },
          {
            title: "commands.logs.title",
            icon: "-",
            route: "commandLogs",
            resource: "Command",
            action: "read",
          },
          {
            title: "action_plan.list.title",
            icon: "-",
            route: "actionPlanLogs",
            resource: "ActionPlanLog",
            action: "read",
          },
          {
            title: "log_microservice.title",
            icon: "-",
            route: "microservice_logs",
            resource: "MicroserviceLogs",
            action: "read",
          },
        ],
      },
      {
        title: "automation.title",
        icon: "-",
        children: [
          {
            title: "commands.title",
            icon: "-",
            route: "command",
            resource: "Command",
            action: "read",
          },
          {
            title: "schedule_task.title",
            icon: "-",
            route: "schedule_task",
            resource: "ScheduleTask",
            action: "read",
          },
          {
            title: "workflow.workflow",
            icon: "-",
            route: "workflow",
            resource: "Workflow",
            action: "read",
          },
          {
            title: "action_plan.title",
            icon: "-",
            route: "action-plan",
            resource: "DecisionTree",
            action: "read",
          },
        ],
      },
      {
        title: "VOIP",
        icon: "-",
        children: [
          {
            title: "iotcomms_master_account.title",
            icon: "-",
            route: "iotcomms_master_account",
            resource: "IotcommsMasterAccount",
            action: "read",
          },
          {
            title: "type_device_interphone.title",
            icon: "-",
            route: "type_device_interphone",
            resource: "TypeDeviceInterphone",
            action: "read",
          },
          {
            title: "device_interphone.title",
            icon: "-",
            route: "device_interphone",
            resource: "DeviceInterphone",
            action: "read",
          },
          {
            title: "sip.view.title",
            icon: "-",
            route: "sip_call",
            resource: "SipCall",
            action: "read_record_call",
          },
        ],
      },
    ],
  },
];
