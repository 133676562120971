<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav menu-fixed menu-accordion menu-shadow"
    :key="updateKey"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue";
import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue";
import { watch, ref, toRefs} from "@vue/composition-api";


export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    userData : {
      type: Object,
      required: false,
    }
  },
  setup(props) {
    const resolveNavComponent = (item) => {
      if (item.children) return "horizontal-nav-menu-header-group";
      return "horizontal-nav-menu-header-link";
    };

    const { userData } = toRefs(props);
     const updateKey = ref(0);

    watch(userData, (val) =>{
        console.log("Entro a actualizar NAV Elements")
        updateKey.value = updateKey.value + 1
    });





    return {
      resolveNavComponent,
      updateKey
    };
  },
};
</script>
